import * as React from 'react'
import Layout from '../components/layout'

const ProductsPage = () => {
    return (
        <Layout title="Products">
            Products page
        </Layout>
    )
}

export default ProductsPage
